import { NavigationPage } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import { Card, NavigationList } from '@/components'
import { useAppSelector } from '@/hooks'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { selectUserHasMadePlanSelection } from '@/selectors'
import { useEnrolledUtilityViewConfig } from '@/reducers/enrolledUtilityPrograms'
import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { useProgramDisabledDevices } from '@/hooks/useProgramDisabledDevices'

const devices = [
  {
    id: 'connect-vehicle',
    deviceType: 'vehicle' as DeviceType,
    name: 'Connect vehicle',
    logo: '/dashboard/img/car_models/tesla/model3/Midnight_Silver_Metallic.webp',
    destination: '/connect-device/vehicle?value_prop=true',
  },
  {
    id: 'connect-thermostat',
    deviceType: 'thermostat' as DeviceType,
    name: 'Connect thermostat',
    logo: '/img/nest.webp',
    destination: '/connect-device/thermostat',
  },
  {
    id: 'connect-charger',
    deviceType: 'vehicleCharger' as DeviceType,
    name: 'Connect charger',
    logo: '/img/chargepoint-charger.webp',
    destination: '/connect-device/charger/value-prop',
  },
  {
    id: 'connect-home-meter',
    deviceType: 'home' as DeviceType,
    name: 'Connect home meter',
    logo: 'img/house.webp',
    destination: '/connect-utility',
  },
]

function useAvailableDevices() {
  const shouldShowConnectCharger = useFeatureFlag({
    flag: 'connect_charger',
    experimentalValue: true,
  })
  const { deviceIsDisabled: deviceDisabledByProgram } =
    useProgramDisabledDevices()

  const shouldShowConnectHome = useAppSelector((state) => {
    const home = state.homes.selectedHome
    const hasSelectedUtility = state.utilities.selectedUtility
    const hasUtilityProviderLink = hasSelectedUtility?.has_api_provider_link
    const hasMadeGridSupportSelection =
      state.user.user?.profile.has_made_grid_support_selection
    const hasPlan = selectUserHasMadePlanSelection(state)
    return (
      !home ||
      (hasUtilityProviderLink && !hasMadeGridSupportSelection) ||
      !hasSelectedUtility ||
      !hasPlan
    )
  })

  return devices.filter((device) => {
    switch (device.deviceType) {
      case 'vehicle':
        return !deviceDisabledByProgram('vehicle')
      case 'thermostat':
        if (!shouldShowConnectCharger) {
          return false
        }
        return !deviceDisabledByProgram('thermostat')
      case 'home':
        if (!shouldShowConnectHome) {
          return false
        }
        return !deviceDisabledByProgram('home')
      case 'vehicleCharger':
        return !deviceDisabledByProgram('vehicleCharger')
      default:
        return true
    }
  })
}

export default function ConnectDevicePage() {
  const navigation = useNavigation()
  const devicesToShow = useAvailableDevices()

  const navigateOnClick = (destination: string) => () =>
    navigation?.push(destination)

  return (
    <NavigationPage id="connect-device-page" title="Connect Device">
      <Card style={{ paddingTop: 0, paddingBottom: 0 }}>
        <NavigationList>
          {devicesToShow.map((device, index) => (
            <NavigationList.BasicItem
              variant="image"
              key={`${device.name}-${index}`}
              id={`${device.id}-nav-item`}
              label={device.name}
              imgSrc={device.logo}
              onClick={navigateOnClick(device.destination)}
            />
          ))}
        </NavigationList>
      </Card>
    </NavigationPage>
  )
}
